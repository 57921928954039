import { useEffect, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $getRoot
} from 'lexical';

type ParagraphPlaceholderPluginProps = {
  placeholder: string;
};

const tailwindPlaceholderClasses = [
  'before:text-npl-text-icon-on-light-surface-tertiary',
  'before:content-[attr(data-placeholder)]',
  'before:pointer-events-none',
  'before:float-left',
  'before:h-0'
];

export const ParagraphPlaceholderPlugin = ({
  placeholder
}: ParagraphPlaceholderPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const paragraphRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        const nativeSelection = window.getSelection();

        editorState.read(() => {
          // Cleanup
          if (paragraphRef?.current) {
            paragraphRef.current.removeAttribute('data-placeholder');
            paragraphRef.current.classList.remove(
              ...tailwindPlaceholderClasses
            );
            paragraphRef.current = null;
          }

          const selection = $getSelection();
          const root = $getRoot();
          const firstChild = root.getFirstChild();

          if ($isParagraphNode(firstChild) && firstChild.isEmpty()) {
            const element = editor.getElementByKey(firstChild.getKey());
            if (element instanceof HTMLElement) {
              paragraphRef.current = element;
              paragraphRef.current.setAttribute(
                'data-placeholder',
                placeholder
              );
              paragraphRef.current.classList.add(
                ...tailwindPlaceholderClasses
              );
            }
          } else if (
            nativeSelection &&
            selection &&
            $isRangeSelection(selection)
          ) {
            const parentNode = selection.anchor.getNode();
            if ($isParagraphNode(parentNode) && parentNode.isEmpty()) {
              const paragraphDOMElement = nativeSelection.anchorNode;
              if (paragraphDOMElement instanceof HTMLParagraphElement) {
                paragraphRef.current = paragraphDOMElement;
                paragraphRef.current.setAttribute(
                  'data-placeholder',
                  placeholder
                );
                paragraphRef.current.classList.add(
                  ...tailwindPlaceholderClasses
                );
              }
            }
          }
        });
      }
    );

    // Initial check for empty state
    editor.getEditorState().read(() => {
      const root = $getRoot();
      const firstChild = root.getFirstChild();
      if ($isParagraphNode(firstChild) && firstChild.isEmpty()) {
        const element = editor.getElementByKey(firstChild.getKey());
        if (element instanceof HTMLElement) {
          paragraphRef.current = element;
          paragraphRef.current.setAttribute(
            'data-placeholder',
            placeholder
          );
          paragraphRef.current.classList.add(
            ...tailwindPlaceholderClasses
          );
        }
      }
    });

    return () => {
      removeUpdateListener();
    };
  }, [editor, placeholder]);

  return null;
};
