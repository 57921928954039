import classNames from 'classnames';
import React from 'react';
import Icon from './Icon';

export const LINK_BTN_HIERARCHY = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  ACCENT: 'Accent'
};

const getTextSizeClassNames = (size) => {
  let className = '';
  switch (size) {
    case 'xs':
      className = 'font-medium text-textlink-sm';
      break;
    case 'sm':
      className = 'font-medium text-textlink-md';
      break;
    case 'md':
      className = 'font-medium text-textlink-lg';
      break;
    case 'lg':
      className = 'font-medium text-textlink-xl';
      break;
    case 'default':
      break;
  }
  return className;
};

const getTextColorClassNames = (hierarchy, darkMode) => {
  let className = '';
  switch (hierarchy) {
    case LINK_BTN_HIERARCHY.PRIMARY:
      if (darkMode) {
        className =
          'text-npl-text-icon-on-dark-primary disabled:text-npl-neutral-dark-solid-8';
      } else {
        className =
          'text-npl-text-icon-on-light-surface-primary disabled:text-npl-neutral-light-solid-8';
      }
      break;
    case LINK_BTN_HIERARCHY.SECONDARY:
      if (darkMode) {
        className =
          'text-npl-text-icon-on-dark-tertiary disabled:text-npl-neutral-dark-solid-7';
      } else {
        className =
          'text-npl-text-icon-on-light-surface-tertiary disabled:text-npl-neutral-light-solid-7';
      }
      break;
    case LINK_BTN_HIERARCHY.ACCENT:
      if (darkMode) {
        className =
          'text-npl-yellow-dark-solid-11 disabled:text-npl-yellow-dark-solid-7';
      } else {
        className =
          'text-npl-yellow-light-solid-11 disabled:text-npl-yellow-light-solid-7';
      }
      break;
  }
  return className;
};

const getIconColorClassNames = (hierarchy, darkMode, disabled) => {
  let fillColor = '';
  switch (hierarchy) {
    case LINK_BTN_HIERARCHY.PRIMARY:
      if (darkMode) {
        fillColor = disabled ? '#52514C' : '#F5F5F5';
      } else {
        fillColor = disabled ? '#C8C7C1' : '#1B1B18';
      }
      break;
    case LINK_BTN_HIERARCHY.SECONDARY:
      if (darkMode) {
        fillColor = disabled ? '#3F3F3B' : '#F5F5F580';
      } else {
        fillColor = disabled ? '#DBDBD7' : '#1B1B1880';
      }
      break;
    case LINK_BTN_HIERARCHY.ACCENT:
      if (darkMode) {
        fillColor = disabled ? '#604506' : '#F0B800';
      } else {
        fillColor = disabled ? '#F0D375' : '#946800';
      }
      break;
  }
  return fillColor;
};

const NPLLinkButton = ({
  size,
  hierarchy,
  darkMode = false,
  buttonText,
  leadIcon = null,
  tailIcon = null,
  disabled = false,
  underline = false,
  onClick
}) => {
  const renderIcon = (iconName) => {
    if (!iconName) return null;

    const widthAndHeight = ['xs', 'sm'].includes(size)
      ? {
          width: 16,
          height: 16
        }
      : {
          width: 20,
          height: 20
        };

    const iconFillColor = getIconColorClassNames(
      hierarchy,
      darkMode,
      disabled
    );
    return (
      <Icon
        name={iconName}
        {...widthAndHeight}
        fill={iconFillColor}
        fillOpacity="1"
      />
    );
  };

  const btnClassNames = classNames(
    'c-NPLLinkButton group flex items-center justify-center space-x-6 rounded-100 disabled:cursor-not-allowed min-w-fit',
    getTextSizeClassNames(size),
    getTextColorClassNames(hierarchy, darkMode)
  );

  return (
    <button
      className={btnClassNames}
      disabled={disabled}
      onClick={onClick ? onClick : () => {}}>
      {renderIcon(leadIcon)}
      <p className={underline ? 'underline' : 'group-hover:underline'}>
        {buttonText}
      </p>
      {renderIcon(tailIcon)}
    </button>
  );
};

export default NPLLinkButton;
