import classNames from 'classnames';
import React from 'react';

import HeadlessSelect from '@/components/common/Form/HeadlessSelect/HeadlessSelect';

import Icon from '../../Icon';

function NPLInputLeadTailSection({
  text,
  iconName,
  selectionProps = {},
  isTail,
  iconOnClick,
  isSlugInput = false
}) {
  const { placeholder, value, options, onChange, showSearch } =
    selectionProps;

  const isTextOrIconRendered = text || iconName;

  const spacingClassName = isTail ? 'ml-16' : 'mr-16';

  return (
    <div
      className={`flex items-center py-12 ${
        isTail ? 'flex-row-reverse pr-16' : 'flex-row pl-16'
      }`}>
      {isTextOrIconRendered && (
        <div
          className={`flex items-center ${spacingClassName} ${
            isTail ? 'flex-row-reverse' : ''
          }`}>
          {text && (
            <>
              <span
                className={`max-w-[105px] flex-shrink-0 flex-grow overflow-hidden text-ellipsis whitespace-nowrap text-body-md text-npl-text-icon-on-light-surface-tertiary`}>
                {text}
              </span>
              {isSlugInput && (
                <span className="text-body-md text-npl-text-icon-on-light-surface-tertiary">
                  /
                </span>
              )}
            </>
          )}
          {iconName && (
            <div
              className={`${spacingClassName} ${
                typeof iconOnClick === 'function'
                  ? 'hover:cursor-pointer'
                  : ''
              }`}
              onClick={iconOnClick}>
              <Icon
                name={iconName}
                width={16}
                height={16}
                fill="#1B1B18A6"
              />
            </div>
          )}
        </div>
      )}

      <div className="hover:cursor-pointer">
        {Object.keys(selectionProps).length > 0 && (
          <HeadlessSelect
            placeholder={placeholder}
            value={value}
            options={options}
            onChange={onChange}
            showSearch={showSearch}
            renderField={({ option, placeholder, isOpen }) => (
              <div
                className={`flex flex-shrink-0 flex-grow items-center gap-8 py-12 ${
                  isTail ? 'pl-16' : 'pr-16'
                }`}>
                <div className="whitespace-nowrap text-body-sm text-npl-text-icon-on-light-surface-primary">
                  {option ? option?.label : placeholder}
                </div>
                <div
                  className={`transition-all duration-200 ${
                    isOpen ? 'rotate-180' : ''
                  }`}>
                  <Icon
                    name="chevron-down"
                    width="20"
                    height="20"
                    fill="#1B1B18A6"
                  />
                </div>
              </div>
            )}
            renderOption={({ value, option, handleOptionClick }) => (
              <div
                role="button"
                tabIndex={0}
                onClick={() =>
                  !option?.disabled ? handleOptionClick(option) : null
                }
                className={classNames(
                  'mx-8 my-4 rounded-8 px-8 py-8 font-normal text-neutral-10 hover:bg-neutral-97',
                  value === option?.value ? 'bg-neutral-95' : '',
                  option?.disabled
                    ? 'cursor-not-allowed text-neutral-70'
                    : ''
                )}>
                {option?.optionsDisplay || option?.label}
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
}

export default NPLInputLeadTailSection;
