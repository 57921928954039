import classNames from 'classnames';
import React from 'react';

import withComponentClassName from '@/utility/withComponentClassName';

function AnimatedShowHideDiv({ children, show, duration = 200 }) {
  return (
    <div
      className={classNames(
        `grid w-full transition-all overflow-hidden ease-out`,
        {
          'grid-rows-[0fr]': !show,
          'grid-rows-[1fr]': show
        }
      )}
      style={{
        transitionDuration: `${duration}ms`
      }}>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
}

export default withComponentClassName(AnimatedShowHideDiv);
